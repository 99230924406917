@font-face {
  font-family: 'WeasyIcon';
  src:  url('WeasyIcon.eot?osix4w');
  src:  url('WeasyIcon.eot?osix4w#iefix') format('embedded-opentype'),
    url('WeasyIcon.ttf?osix4w') format('truetype'),
    url('WeasyIcon.woff?osix4w') format('woff'),
    url('WeasyIcon.svg?osix4w#WeasyIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'WeasyIcon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wi-birthday-cake-solid:before {
  content: "\e930";
}
.wi-camera-solid:before {
  content: "\e931";
}
.wi-file-medical-solid:before {
  content: "\e932";
}
.wi-home-solid:before {
  content: "\e933";
}
.wi-id-card-regular:before {
  content: "\e934";
}
.wi-life-ring-solid:before {
  content: "\e935";
}
.wi-money-check-alt-solid:before {
  content: "\e936";
}
.wi-wallet-solid:before {
  content: "\e937";
}
.wi-trophy-solid:before {
  content: "\e92d";
}
.wi-dumbbell-solid:before {
  content: "\e92e";
}
.wi-futbol-solidr:before {
  content: "\e92f";
}
.wi-ellipsis-h-solid:before {
  content: "\e924";
}
.wi-ellipsis-v-solid:before {
  content: "\e925";
}
.wi-filter-solid:before {
  content: "\e926";
}
.wi-login-solid:before {
  content: "\e927";
}
.wi-logout-solid:before {
  content: "\e928";
}
.wi-sort-solid:before {
  content: "\e929";
}
.wi-wg-logo:before {
  content: "\e92a";
}
.wi-pencil-alt-solid:before {
  content: "\e92b";
}
.wi-bolt-solid:before {
  content: "\e92c";
}
.wi-google .path1:before {
  content: "\e91d";
  color: rgb(66, 133, 244);
}
.wi-google .path2:before {
  content: "\e91e";
  margin-left: -0.96875em;
  color: rgb(52, 168, 83);
}
.wi-google .path3:before {
  content: "\e91f";
  margin-left: -0.96875em;
  color: rgb(251, 188, 4);
}
.wi-google .path4:before {
  content: "\e920";
  margin-left: -0.96875em;
  color: rgb(234, 67, 53);
}
.wi-user-solid:before {
  content: "\e921";
}
.wi-key-solid:before {
  content: "\e922";
}
.wi-envelope-solid:before {
  content: "\e923";
}
.wi-dollar-sign-solid:before {
  content: "\e91c";
}
.wi-teams:before {
  content: "\e91a";
}
.wi-delete:before {
  content: "\e91b";
}
.wi-user-card-id:before {
  content: "\e90f";
}
.wi-card:before {
  content: "\e918";
}
.wi-list:before {
  content: "\e919";
}
.wi-plus:before {
  content: "\e917";
}
.wi-arrow-left:before {
  content: "\e900";
}
.wi-arrow-right:before {
  content: "\e901";
}
.wi-arrow-up:before {
  content: "\e902";
}
.wi-card-index:before {
  content: "\e903";
}
.wi-check:before {
  content: "\e904";
}
.wi-dashboard:before {
  content: "\e905";
}
.wi-export:before {
  content: "\e906";
}
.wi-gg-logo:before {
  content: "\e907";
}
.wi-import:before {
  content: "\e908";
}
.wi-infinite:before {
  content: "\e909";
}
.wi-plaster:before {
  content: "\e90a";
}
.wi-search:before {
  content: "\e90b";
}
.wi-sort-asc:before {
  content: "\e90c";
}
.wi-sort-desc:before {
  content: "\e90d";
}
.wi-stats:before {
  content: "\e90e";
}
.wi-agenda:before {
  content: "\e910";
}
.wi-alert:before {
  content: "\e911";
}
.wi-angle-down:before {
  content: "\e912";
}
.wi-angle-left:before {
  content: "\e913";
}
.wi-angle-right:before {
  content: "\e914";
}
.wi-angle-top:before {
  content: "\e915";
}
.wi-arrow-down:before {
  content: "\e916";
}
